import Swiper, { Autoplay } from "swiper"
import "../../../node_modules/swiper/swiper-bundle.css"
import { useRef, useState, useEffect } from "react"

Swiper.use([Autoplay])

export const useSwiper = (slides, options) => {
  const swiperElement = useRef(null)
  const swiperInstance = useRef(null)
  const [activeSlideIndex, setActiveSlideIndex] = useState(0)

  useEffect(() => {
    const defaultOptions = {
      loop: true,
      on: {
        slideChange: function () {
          setActiveSlideIndex(
            swiperInstance.current &&
              swiperInstance.current.realIndex !== undefined
              ? swiperInstance.current.realIndex
              : 0
          )
        },
        slideChangeTransitionStart: function () {
          const isTheLastSlide =
            this.activeIndex - this.loopedSlides === slides.length + 1
          if (isTheLastSlide) {
            return this.slideToLoop(0)
          }
          const isTheFirstSlide = this.activeIndex === 0
          if (isTheFirstSlide) {
            return this.slideToLoop(slides.length - 1)
          }
        },
      },
      pagination: {
        dynamicBullets: true,
        dynamicMainBullets: 3,
        type: "progressbar",
      },
    }
    swiperInstance.current = new Swiper(swiperElement.current, {
      ...defaultOptions,
      ...options,
    })
    return () => swiperInstance.current.destroy()
  }, [slides.length]) // eslint-disable-line react-hooks/exhaustive-deps
  return { swiperInstance, swiperElement, activeSlideIndex }
}
